var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isBusy,"rounded":"sm"}},[_c('div',{staticClass:"mx-4 support-mode"},[_c('b-col',{attrs:{"cols":"12"}},[(_vm.actionMessage)?_c('b-alert',{attrs:{"show":"","variant":_vm.actionMessage.class,"dismissible":""}},[_vm._v(" "+_vm._s(_vm.actionMessage.msg)+" ")]):_vm._e()],1),_c('b-row',{staticClass:"my-4 px-4",attrs:{"no-gutters":"","align-v":"start","align-h":"start"}},[_c('b-col',{staticClass:"text-start",attrs:{"cols":"6"}},[_c('h5',{staticClass:"my-5"},[_vm._v(" "+_vm._s(_vm.$t(("project.section.informationModal.settingsTab." + _vm.sectionTypeName + "SettingsTitle")))+" ")])]),_c('b-col',{attrs:{"cols":"12"}}),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('project.section.informationModal.settingsTab.options.one')}},[_c('b-form-checkbox',{attrs:{"button-variant":"success","name":"output1","switch":"","size":"lg"},model:{value:(_vm.sectionSettings.outputOne),callback:function ($$v) {_vm.$set(_vm.sectionSettings, "outputOne", $$v)},expression:"sectionSettings.outputOne"}},[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.options.one"))+" ")])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('project.section.informationModal.settingsTab.options.two')}},[_c('b-form-checkbox',{attrs:{"button-variant":"success","name":"output2","switch":"","size":"lg"},model:{value:(_vm.sectionSettings.outputTwo),callback:function ($$v) {_vm.$set(_vm.sectionSettings, "outputTwo", $$v)},expression:"sectionSettings.outputTwo"}},[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.options.two"))+" ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}}),(
          _vm.isUserAdmin &&
          _vm.sectionInformation.isMeasure &&
          _vm.sectionInformation.state.stateDetails.deviceStateOne.rollAngleUnit == 'Degree'
        )?_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.options.rollAngle"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"input-width",attrs:{"type":"number","step":"0.01"},model:{value:(_vm.sectionSettings.rollAngle),callback:function ($$v) {_vm.$set(_vm.sectionSettings, "rollAngle", $$v)},expression:"sectionSettings.rollAngle"}}),(_vm.mainBoardReasons.length > 0)?_c('div',_vm._l((_vm.mainBoardReasons),function(item){return _c('div',{key:item.detail},[(item.label.split('.')[4] == 'measure_roll_angle_settings_mismatch')?_c('a',{staticClass:"missmatch-angle input-width",class:item.label.split('.')[3] === 'warning' ? 'text-warning' : 'text-danger',on:{"click":_vm.changeRollAngleValue}},[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.measureSettingsAngleAccept"))+" "+_vm._s(_vm.sectionInformation.state.stateDetails.deviceStateOne.rollAngle)+" "),_c('i',{staticClass:"fa fa-arrow-up",attrs:{"aria-hidden":"true"}})]):_vm._e()])}),0):(
                  _vm.sectionSettings.rollAngle != _vm.sectionInformation.state.stateDetails.deviceStateOne.rollAngle
                )?_c('div',[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"missmatch-angle text-warning",attrs:{"href":"javascript:void(0)","title":_vm.$t('project.section.informationModal.settingsTab.measureSettingsAngleAccept')},on:{"click":_vm.changeRollAngleValue}},[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.measureSettingsAngleAccept"))+" "+_vm._s(_vm.sectionInformation.state.stateDetails.deviceStateOne.rollAngle)+" "),_c('i',{staticClass:"fa fa-arrow-up",attrs:{"aria-hidden":"true"}})])]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.options.pitchAngle"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"input-width",attrs:{"type":"number","step":"0.01"},model:{value:(_vm.sectionSettings.pitchAngle),callback:function ($$v) {_vm.$set(_vm.sectionSettings, "pitchAngle", $$v)},expression:"sectionSettings.pitchAngle"}}),(_vm.mainBoardReasons.length > 0)?_c('div',_vm._l((_vm.mainBoardReasons),function(item){return _c('div',{key:item.detail},[(item.label.split('.')[4] == 'measure_pitch_angle_settings_mismatch')?_c('a',{staticClass:"missmatch-angle input-width",class:item.label.split('.')[3] === 'warning' ? 'text-warning' : 'text-danger',on:{"click":_vm.changePitchAngleValue}},[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.measureSettingsAngleAccept"))+" "+_vm._s(_vm.sectionInformation.state.stateDetails.deviceStateOne.pitchAngle)+" "),_c('i',{staticClass:"fa fa-arrow-up",attrs:{"aria-hidden":"true"}})]):_vm._e()])}),0):(
                  _vm.sectionSettings.pitchAngle != _vm.sectionInformation.state.stateDetails.deviceStateOne.pitchAngle
                )?_c('div',[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"missmatch-angle text-warning",attrs:{"href":"javascript:void(0)","title":_vm.$t('project.section.informationModal.settingsTab.measureSettingsAngleAccept')},on:{"click":_vm.changePitchAngleValue}},[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.measureSettingsAngleAccept"))+" "+_vm._s(_vm.sectionInformation.state.stateDetails.deviceStateOne.pitchAngle)+" "),_c('i',{staticClass:"fa fa-arrow-up",attrs:{"aria-hidden":"true"}})])]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.options.angle_threshold1"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"input-width",attrs:{"type":"number","step":"0.01"},model:{value:(_vm.sectionSettings.angleThreshold1),callback:function ($$v) {_vm.$set(_vm.sectionSettings, "angleThreshold1", $$v)},expression:"sectionSettings.angleThreshold1"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.options.angle_threshold2"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"input-width",attrs:{"type":"number","step":"0.01"},model:{value:(_vm.sectionSettings.angleThreshold2),callback:function ($$v) {_vm.$set(_vm.sectionSettings, "angleThreshold2", $$v)},expression:"sectionSettings.angleThreshold2"}}),(
                  parseFloat(this.sectionSettings.angleThreshold2) <= parseFloat(this.sectionSettings.angleThreshold1)
                )?_c('p',{staticClass:"missmatch-angle text-danger"},[_vm._v(" "+_vm._s(_vm.$t("project.section.informationModal.settingsTab.measureThresholdWarning"))+" ")]):_vm._e()],1)],1)],1)],1):_vm._e(),_c('b-col',{staticClass:"offset-md-6",attrs:{"cols":"12","md":"6"}},[_c('b-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"ml-auto mb-2 mb-md-0",attrs:{"cols":"12","md":"6"}}),_c('b-col',{staticClass:"pl-md-2",attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"disabled":_vm.checkValidation,"variant":"success","block":""},on:{"click":_vm.handleUpdateMeasureAndCamera}},[_vm._v(" "+_vm._s(_vm.$t("utils.modal.updateUser"))+" ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }